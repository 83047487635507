/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.2/dist/jquery.min.js
 * - /npm/animsition@4.0.2/dist/js/animsition.min.js
 * - /npm/jquery-match-height@0.7.2/dist/jquery.matchHeight.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/aos@2.3.4/dist/aos.min.js
 * - /npm/slicknav@1.0.8/dist/jquery.slicknav.min.js
 * - /npm/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.concat.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/owl.carousel2.thumbs@0.1.8/dist/owl.carousel2.thumbs.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe-ui-default.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
